<template>
  <div class="Landing_PaymentHub" id="payment-hub">
    <div class="Landing_PaymentHubWrapper">
      <div class="Landing_PaymentHubTitle title-h2">Payment Hub</div>
      <div class="Landing_PaymentHubText SectionContent">
        <p>
          KAURI Payment Hub – next generation payment hub that has 30+ most used crypto & traditional payment methods integrated in 1 API
          for online businesses and merchants.
        </p>
      </div>
      <div class="Landing_PaymentHubList" ref="icons">
        <div class="Landing_PaymentHubListHead">
          <img src="@/assets/img/landing/payment-hub-01.svg" alt="icon" />
          <img src="@/assets/img/landing/payment-hub-02.svg" alt="" />
        </div>
        <ul>
          <li ref="icon" v-for="(icon, index) in icons" v-bind:key="index">
            <img :class="icon.class" :src="icon.url" alt="icon" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { url: require('@/assets/img/landing/payment-hub-1.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-2.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-3.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-4.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-5.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-6.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-7.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-8.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-9.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-10.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-11.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-12.png'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-13.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-14.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-15.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-16.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-17.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-18.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-19.svg'), class: '' },
      { url: require('@/assets/img/landing/payment-hub-20.svg'), class: '' },
    ],
  }),
  mounted() {
    this.setIconsAlignClass();
  },
  methods: {
    handleResize() {
      this.setIconsAlignClass();
    },
    setIconsAlignClass() {
      let cols = Math.round(this.$refs.icon[0].parentElement.offsetWidth / this.$refs.icon[0].offsetWidth);
      this.icons.forEach((icon, index) => {
        if (Math.floor(index / cols) % 2 == 0) {
          // console.log('align-rigth')
          this.icons[index].class = 'align-rigth';
        } else {
          // console.log('align-left')
          this.icons[index].class = 'align-left';
        }
      });
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import './PaymentHub.scss';
</style>
