import { render, staticRenderFns } from "./KauriPay.vue?vue&type=template&id=adc2b232&scoped=true&"
var script = {}
import style0 from "./KauriPay.vue?vue&type=style&index=0&id=adc2b232&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc2b232",
  null
  
)

export default component.exports