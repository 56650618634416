<template>
  <div class="Landing_wrapper">
    <Hero />
    <InvestFund />
    <CryptoBroker />
    <CryptoProcessing />
    <PaymentHub />
    <NFTBroker />
    <KauriPay />
    <Licenses />
    <Verification />
    <FeesAndLimits />
    <Partners />
    <Team />
    <!-- <FooterVideo /> -->
  </div>
</template>

<script>
import Hero from './_components/Hero/Hero';
import InvestFund from './_components/InvestFund/InvestFund';
import CryptoBroker from './_components/CryptoBroker/CryptoBroker';
import CryptoProcessing from './_components/CryptoProcessing/CryptoProcessing';
import PaymentHub from './_components/PaymentHub/PaymentHub';
import NFTBroker from './_components/NFTBroker/NFTBroker';
import KauriPay from './_components/KauriPay/KauriPay';
import Licenses from './_components/Licenses/Licenses';
import Verification from './_components/Verification/Verification';
import FeesAndLimits from './_components/FeesAndLimits/FeesAndLimits';
import Partners from './_components/Partners/Partners';
import Team from './_components/Team/Team';
// import FooterVideo from './_components/FooterVideo/FooterVideo';

export default {
  components: {
    Hero,
    InvestFund,
    CryptoBroker,
    CryptoProcessing,
    PaymentHub,
    NFTBroker,
    KauriPay,
    Licenses,
    Verification,
    FeesAndLimits,
    Partners,
    Team,
    // FooterVideo,
  },
};
</script>

<style lang="scss">
// @import '@/assets/scss/_variables.scss';
// @import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

.Landing_wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  @include landingMontserratFont;
}

.SectionContent > *:last-child {
  margin-bottom: 0;
}
</style>
