<template>
  <section class="Landing_hero" id="home">
    <video
      autoplay=""
      loop=""
      muted=""
      :control="false"
      src="@/assets/img/landing/videos/landingheroVideo.mp4"
    ></video>
    <div
      class="Landing_heroWrapper"
      :style="{ paddingTop: `${ headerHeight + 70 }px` }"
    >
      <div class="Landing_heroDesc">
        <h1 class="Landing_heroTitle">Kauri.Finance <br /><b>Financial platform</b></h1>
        <p class="Landing_heroText">
          Kauri.Finance – EU licensed multifunctional investment and payment solution to enable any crypto operations for businesses and
          professional traders
        </p>
        <div class="Landing_heroBtns">
          <router-link
            class="button lightblue"
            :to="{ name: 'login' }"
          >Login
          </router-link>
          <router-link
            class="button transp"
            :to="{ name: 'registration' }"
          >
            Registration
          </router-link>
        </div>
      </div>
      <a
        href="#invest-fund"
        v-smooth-scroll="{ offset: -76 }"
        class="Landing_heroScroll"
      ></a>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      headerHeight: 0,
    };
  },
  methods: {
    observeHeight() {
      const header = document.getElementById('header')
      if (!header) return
      const resizeObserver = new ResizeObserver(() => {
        this.headerHeight = header.offsetHeight
      });
      resizeObserver.observe(header)
    }
  },
  mounted() {
    this.observeHeight()  
  },
}
</script>

<style lang="scss" scoped>
@import './Hero.scss';
</style>
