<template>
  <div class="Landing_FeesAndLimits" id="fees-and-limits">
    <div class="Landing_FeesAndLimitsWrapper">
      <div class="Landing_FeesAndLimitsBody">
        <div class="Landing_FeesAndLimitsTitle">Fees and limits</div>
        <LimitsTable />
      </div>
    </div>
  </div>
</template>

<script>
import LimitsTable from '@/components/_common/Limits/Limits';
export default {
  components: {
    LimitsTable,
  },
};
</script>

<style lang="scss" scoped>
@import './FeesAndLimits.scss';
</style>
