<template>
  <div class="LimitsTableWrap">
    <table class="LimitsTable" border="0" cellspacing="0" cellpadding="0">
      <tr class="LimitsTableHead">
        <th>Cryptocurrency</th>
        <td></td>
        <td></td>
        <th>Minimal withdrawal</th>
        <th>Withdrawal Fee</th>
      </tr>
      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/bitcoin.svg" alt="" />
            </div>
            <b>Bitcoin (BTC)</b>
          </div>
        </td>
        <td></td>
        <td></td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralLimit('withdrawal_order_limits.VERIFIED.BTC.GATEWAY.min_amount') }}<span class="LimitTableTdGreyText">= $13.2</span></div>
        </td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralFees('withdrawal_order_fees.VERIFIED.BTC.GATEWAY') }}<span class="LimitTableTdGreyText">= $6.6</span></div>
        </td>
      </tr>
      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/ethereum.svg" alt="" />
            </div>
            <b>Ethereum (ETH)</b>
          </div>
        </td>
        <td></td>
        <td></td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralLimit('withdrawal_order_limits.VERIFIED.ETH.GATEWAY.min_amount') }}<span class="LimitTableTdGreyText">= $13.2</span></div>
        </td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralFees('withdrawal_order_fees.VERIFIED.ETH.GATEWAY') }}<span class="LimitTableTdGreyText">= $6.6</span></div>
        </td>
      </tr>
      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/tether.svg" alt="" />
            </div>
            <b>Tether (USDT)</b>
          </div>
        </td>
        <td></td>
        <td></td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralLimit('withdrawal_order_limits.VERIFIED.USDT.GATEWAY.ERC20.min_amount') }}<span class="LimitTableTdGreyText">= $13.2</span></div>
        </td>
        <td>
          <div class="LimitTableTdWrapper LimitTableTdWrapperColumn">{{ getGeneralFees('withdrawal_order_fees.VERIFIED.USDT.GATEWAY.ERC20') }} <span class="LimitTableTdGreyText">= $6.6</span></div>
        </td>
      </tr>
      <tr class="LimitsTableSep"></tr>

      <tr class="LimitsTableHead">
        <td>USD</td>
        <td>Deposit</td>
        <td>Withdrawal</td>
        <td>Deposit Fee</td>
        <td>Withdrawal Fee</td>
      </tr>

      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/swift.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.USD.SWIFT.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.USD.SWIFT.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.USD.SWIFT.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.USD.SWIFT.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.USD.SWIFT.P2P') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.USD.SWIFT') }}</td>
      </tr>

      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/VISA-2.svg" />
              <img src="@/assets/img/landing/payments/MC-2.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.USD.GATEWAY.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.USD.GATEWAY.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.USD.GATEWAY.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.USD.GATEWAY.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.USD.GATEWAY.P2P') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.USD.GATEWAY') }}</td>
      </tr>

      <tr class="LimitsTableSep"></tr>

      <tr class="LimitsTableHead">
        <td>EUR</td>
        <td>Deposit</td>
        <td>Withdrawal</td>
        <td>Deposit Fee</td>
        <td>Withdrawal Fee</td>
      </tr>

      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/swift.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.SWIFT.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.SWIFT.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.SWIFT.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.SWIFT.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.EUR.SWIFT.P2P') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.EUR.SWIFT') }}</td>
      </tr>

      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/VISA-2.svg" />
              <img src="@/assets/img/landing/payments/MC-2.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.GATEWAY.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.GATEWAY.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.GATEWAY.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.GATEWAY.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.EUR.GATEWAY.P2P') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.EUR.GATEWAY') }}</td>
      </tr>
      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/SEPA-2.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.SEPA.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.EUR.SEPA.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.SEPA.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.EUR.SEPA.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.EUR.SEPA') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.EUR.SEPA') }}</td>
      </tr>
      <tr class="LimitsTableSep"></tr>
      <tr class="LimitsTableHead">
        <td>UAH</td>
        <td>Deposit</td>
        <td>Withdrawal</td>
        <td>Deposit Fee</td>
        <td>Withdrawal Fee</td>
      </tr>
      <tr class="LimitsTableBorder">
        <td>
          <div class="LimitTableTdWrapper">
            <div class="LimitsTableIconWrapper">
              <img src="@/assets/img/landing/payments/VISA-2.svg" />
              <img src="@/assets/img/landing/payments/MC-2.svg" />
            </div>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('deposit_order_limits.VERIFIED.UAH.GATEWAY.P2P.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('deposit_order_limits.VERIFIED.UAH.GATEWAY.P2P.max_amount') }}</span>
          </div>
        </td>
        <td>
          <div class="LimitTableTdWrapperContainer">
            <span>min: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.UAH.GATEWAY.min_amount') }}</span>
            <span>max: {{ getGeneralLimit('withdrawal_order_limits.VERIFIED.UAH.GATEWAY.max_amount') }}</span>
          </div>
        </td>
        <td>{{ getGeneralFees('deposit_order_fees.VERIFIED.UAH.GATEWAY.P2P') }}</td>
        <td>{{ getGeneralFees('withdrawal_order_fees.VERIFIED.UAH.GATEWAY') }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState('user', ['generalFeesLimitsFlatten']),
    ...mapGetters('user', ['getGeneralFees', 'getGeneralLimit']),
  },
  methods: {
    ...mapActions('user', ['updateGeneralFeesLimits']),
  },
  mounted() {
    this.updateGeneralFeesLimits()
  }
}
</script>

<style lang="scss">
@import './Limits.scss';
</style>
